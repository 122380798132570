<template>
  <div>
    <h4 class="title has-text-centered is-4">Edit Furniture</h4>

    <div class="columns">
      <div class="column is-6">
        <form @submit="handleSubmit">
          <b-field label="Name" custom-class="is-small">
            <b-input v-model="formData.name" size="is-small"></b-input>
          </b-field>

          <b-field label="Quantity" custom-class="is-small">
            <b-input v-model="formData.quantity" size="is-small"></b-input>
          </b-field>

          <hr />

          <b-field label="Currency" custom-class="is-small">
            <b-select v-model="formData.price_type" size="is-small">
              <option value="monetti">Monetti</option>
              <option value="currants">Currants</option>
            </b-select>
          </b-field>

          <b-field v-if="formData.price_type ==='monetti'" label="Monetti Price" custom-class="is-small">
            <b-input v-model="formData.monetti_price" size="is-small"></b-input>
          </b-field>

          <b-field v-if="formData.price_type ==='currants'" label="Currants Price" custom-class="is-small">
            <b-input v-model="formData.currants_price" size="is-small"></b-input>
          </b-field>

          <b-button type="is-success" native-type="submit" icon-left="check">Update</b-button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { Furniture } from '@/services/api';

export default {
  name: 'furniture-edit-form',
  props: {
    furniture: Object,
  },
  watch: {
    furniture: {
      immediate: true,
      handler(val) {
        this.formData = {
          ...val,
          price_type: val.monetti_price ? 'monetti' : 'currants',
        };
      },
    },
  },
  data() {
    return {
      errors: [],
      formData: {
        name: null,
      },
      loadingForm: false,
      sent: false,
    };
  },
  methods: {
    handleSubmit(event) {
      event.preventDefault();

      this.errors = [];
      this.loadingForm = true;

      Furniture.updateFurniture(this.furniture.id, this.formData)
        .then(() => {
          this.sent = true;
          this.refreshFurniture();
        })
        .catch((error) => {
          const responseError = error.response.data;

          // Validation errors
          if (responseError.errors) {
            Object.keys(responseError.errors).forEach((key) => {
              this.errors.push(responseError.errors[key][0]);
            });

            // General error
          } else {
            this.errors.push('Sorry :( Something went wrong... please try again later');
          }
        })
        .finally(() => {
          this.loadingForm = false;
        });
    },
    refreshFurniture() {
      this.$store.dispatch('furniture/loadFurnitureDetails', this.furniture.id);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
