<template>
  <div>
    <h4 class="title has-text-centered is-4">Sales</h4>

    <ul>
      <li v-for="furnitureSale in furniture.furniture_sales" :key="furnitureSale.id">
        {{ furnitureSale.created_at | dateStandard }} - {{ furnitureSale.furniture_id }}
        ({{ furnitureSale.monetti_price || furnitureSale.currants_price }} {{ furnitureSale.monetti_price ? 'monetti' : 'currants'}})
      </li>
    </ul>
  </div>
</template>

<script>

export default {
  name: 'furniture-sales',
  props: {
    furniture: Object,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
