<template>
  <div>
    <h4 class="title has-text-centered is-4">Owners</h4>

    <ul>
      <li v-for="user in furniture.users" :key="user.id">
        <router-link :to="`/users/${user.id}`">
          {{ user.stack}} items owned by - {{ user.name }} ({{ user.email }})
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>

export default {
  name: 'furniture-users',
  props: {
    furniture: Object,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
