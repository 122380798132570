<template>
  <section class="section">
    <b-loading :is-full-page="false" v-model="loadingFurnitureDetails"></b-loading>

    <div v-if="selectedFurniture">
      <div class="columns mb-6">
        <div class="column is-3">
          <img :src="imageSource(selectedFurniture.id)" class="furniture-image-large" />
        </div>
        <div class="column is-9">
          <h4 class="title is-4">{{ selectedFurniture.name }}<br /><small>{{ selectedFurniture.id }}</small></h4>
          <h6 class="title is-6">{{ selectedFurniture.category.name }}</h6>
        </div>
      </div>
      <b-tabs type="is-boxed" expanded>
        <b-tab-item label="Details">
          <furniture-edit-form :furniture="selectedFurniture"></furniture-edit-form>
        </b-tab-item>
        <b-tab-item label="Owners">
          <furniture-users :furniture="selectedFurniture"></furniture-users>
        </b-tab-item>
        <b-tab-item label="Transactions">
          <furniture-sales :furniture="selectedFurniture"></furniture-sales>
        </b-tab-item>
      </b-tabs>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import FurnitureEditForm from '@/components/Furniture/EditForm.vue';
import FurnitureUsers from '@/components/Furniture/FurnitureUsers.vue';
import FurnitureSales from '@/components/Furniture/FurnitureSales.vue';
import { getEncyclopediaImageUrl } from '../../services/encyclopedia';

export default {
  name: 'UserDetails',
  components: {
    FurnitureSales,
    FurnitureUsers,
    FurnitureEditForm,
  },
  props: {
    furniture: String,
  },
  computed: {
    ...mapState('furniture', ['loadingFurnitureDetails', 'selectedFurniture']),
  },
  mounted() {
    this.loadFurniture(this.furniture);
  },
  beforeRouteUpdate(to, from, next) {
    this.loadFurniture(to.params.furniture);
    next();
  },
  methods: {
    loadFurniture(id) {
      this.$store.dispatch('furniture/loadFurnitureDetails', id);
    },
    imageSource(id) {
      return getEncyclopediaImageUrl('furniture', id, false);
    },
  },
};
</script>

<style lang="scss">
.stat-list > div {
  font-size: 14px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;

  .user-stat-icon {
    width: 18px;
    height: 18px;
    margin-right: 3px;
  }
}
.furniture-image-large {
  max-height: 120px;
}
</style>
